import React, { useEffect, useMemo, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { AdminPortalLayout } from '@digitalworkflow/dwreactcommon'

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { reactPluginAppInsights } from './AzureInsights'

import '@digitalworkflow/dwreactcommon/dist/index.css'
import './assets/scss/index.scss'
import SetToken from './pages/SetToken'
import BuildingSearch from './pages/BuildingSearch'
import LookupManager from './pages/LookupManager'
import ComingSoon from './pages/ComingSoon'
import PageLoader from './pages/PageLoader'
import SurveyLoader from './pages/SurveyLoader'
import ThemeLoader from './pages/ThemeLoader'
import { Spinner } from 'reactstrap'

import { useAuth } from './context/AuthContext'

import logoImageBgis from './assets/images/bgis-logo.png'
import logoSmImageBgis from './assets/images/bgis-logo-sm.png'

import logoImageRps from './assets/images/io-logo.png'
import logoSmImageRps from './assets/images/io-logo-sm.png'

import IdleWatch from './components/IdleWatch'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { menuItems, profileMenuItems, getLoginPortalID } from './constants'

import { Portal } from './types/Portal'
import ForceUpdate from './components/ForceUpdaate/ForceUpdate'
import TestAccounts from './components/TestAccounts/TestAccounts'
import packageInfo from '../package.json'
import TraceFeature from './components/TraceFeature'
import { GlobalHashHelper } from './utils/HashHelper'
import { checkAdminDeveloper } from './utils/checkAdminDeveloper'
import { LocalSettings } from './utils/LocalSettings'
import ForceLogout from './pages/ForceLogout/ForceLogout'
import Reload from './pages/Reload'
import TaskManager from './components/TaskManager/TaskManager'
import { CommonHelper, PSL, PSLHelper } from '@digitalworkflow/dwtranslateclient'
import { Environment } from '@digitalworkflow/dwloginclient'

let globalTheme: string = 'bgis'

const GuestRouteRedirect = () => {
  // Capture the internal URL
  const internalUrl = window.location.pathname

  if (internalUrl !== '/reload') {
    // Store the internal URL in local storage
    LocalSettings.setPortalRedirectUrl(internalUrl)
  }

  window.location.href =
    internalUrl === '/reload'
      ? `${PSLHelper.LoginPortalUrl()}/logout/${getLoginPortalID()}?guest=1&${
          window.location.host.match(/local/) ? 'return=local' : ''
        }`
      : `${PSLHelper.LoginPortalUrl()}/login/${getLoginPortalID()}?guest=1&${
          window.location.host.match(/local/) ? 'return=local' : ''
        }`

  return null
}

const App = () => {
  const { isAuthenticated, user } = useAuth()
  const [portal, setPortal] = useState<Portal>()
  const [showTraceFeauture, setShowTraceFeauture] = useState<boolean>(false)
  const [adminDeveloper, setAdminDeveloper] = useState<boolean>(false)
  const [isPSLIntialize, setIsPSLIntialize] = useState<boolean>(true)

  const navigate = useNavigate()

  const Initialization = async () => {
    setIsPSLIntialize(true)
    console.log('Initializing DWPortal with Environment: ', process.env.REACT_APP_PSL_ENV)
    CommonHelper.setEnvironment(process.env.REACT_APP_PSL_ENV as Environment)
    PSL.SetProjectName(process.env.REACT_APP_PSL_PROJECT_NAME || 'dwportal')
    PSL.initLocalStorage()
    await PSLHelper.initialize()
    setIsPSLIntialize(false)
  }

  useEffect(() => {
    let result = checkAdminDeveloper()
    if (document.location.hostname.match(/localhost/)) result = true
    if (PSL.ProdRules()) result = false
    setAdminDeveloper(result)
    Initialization()
  }, [])

  useEffect(() => {
    if (user.portals_enabled && user.portals_enabled?.length > 0) {
      getPortalDetails(getLoginPortalID())
    }
  }, [user.portals_enabled])

  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      console.log('Window Event:', event)
    })
  }, [])

  const getPortalDetails = (id: string) => {
    try {
      const portalInfo = user.portals_enabled?.find((item) => item.login_portal_id === id)
      if (portalInfo) {
        const portalData: Portal = {
          buttons: portalInfo.buttons,
          login_portal_id: portalInfo.login_portal_id,
          portal_logo: portalInfo.portal_logo,
          portal_name: portalInfo.portal_name,
          redirect_location_after_success: portalInfo.redirect_location_after_success,
          portal_theme: portalInfo.portal_theme,
          portal_logo_full: ''
        }

        if (GlobalHashHelper.hasOption('rps')) {
          handleTheme('io')
        } else {
          if (LocalSettings.getTheme() !== 'io') handleTheme(portalData?.portal_theme ?? '')
        }

        setPortal(portalData)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleTheme = (theme: string) => {
    LocalSettings.setTheme(theme)
    globalTheme = theme
    document.body.classList.add('theme-' + theme ?? 'bgis')
  }

  const logo = useMemo((): string => {
    let logoIcon = logoImageBgis
    if (globalTheme === 'io') {
      logoIcon = logoImageRps
      return logoIcon
    }
    if (portal?.portal_logo) {
      logoIcon = `${PSLHelper.LoginPortalUrl()}/api/v1/portals/logo/${portal.portal_logo}`
    }
    return logoIcon
  }, [portal])

  const env = useMemo((): string => PSL.GetEnvironment(), [])

  const handleSearch = (str: string) => {
    str = str.replace('_task', '')
    navigate(`/page/search/${str}`)
  }

  return (
    <>
      {!isPSLIntialize ? (
        <AppInsightsContext.Provider value={reactPluginAppInsights}>
          <TraceFeature isOpen={showTraceFeauture} setIsOpen={setShowTraceFeauture} />
          <IdleWatch />
          <Routes>
            <Route path='/' element={<SetToken />} />
            <Route path='/logout' element={<ForceLogout />} />
            <Route path='/MK/:magicKey/*' element={<SurveyLoader />} />
            <Route path='/io/*' element={<ThemeLoader />} />
            <Route path='/manage/*' element={<TaskManager />} />
            <Route
              path='/'
              element={
                isAuthenticated
                  ? React.createElement(AdminPortalLayout, {
                      menuItems,
                      profileMenuItems,
                      logoImage: logo,
                      logoSmImage: globalTheme === 'io' ? logoSmImageRps : logoSmImageBgis,
                      username: user?.email,
                      hideTranslate: true,
                      showWorldClock: false,
                      versionNumber: packageInfo.version,
                      env: env,
                      showGearIcon: adminDeveloper,
                      handleSetting: () => setShowTraceFeauture((prev) => !prev),
                      handleSearch: handleSearch
                    })
                  : React.createElement(GuestRouteRedirect, {})
              }
            >
              <Route path='building-search' element={<BuildingSearch />} />
              <Route path='dashboard' element={<ComingSoon />} />
              <Route path='task-inbox' element={<ComingSoon />} />
              <Route path='force-update' element={<ForceUpdate />} />
              <Route path='test-accounts' element={<TestAccounts />} />
              <Route path='lookup-manager' element={<LookupManager />} />
              <Route path='reload' element={<Reload />} />
              <Route path='*' element={<PageLoader />} />
            </Route>
          </Routes>
          <ToastContainer
            position='bottom-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light'
          />
        </AppInsightsContext.Provider>
      ) : (
        <div className='d-flex justify-content-center'>
          <Spinner style={{ height: '30px', width: '30px' }} />
        </div>
      )}
    </>
  )
}

export default App
