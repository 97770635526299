import React, { useEffect, useRef, useState } from 'react'
import { Row, Col } from 'reactstrap'
import { StepSection } from '@digitalworkflow/dwtranslateclient'
import { Notepad } from '@digitalworkflow/dwreactcommon'

import './index.scss'
import { getNotepadColumns, getWidthColumns } from '../../../helpers/pageEngineHelper'

interface Props {
  /** Components to be rendered inside card body.  */
  components: JSX.Element[]
  /** Step data. */
  step: StepSection
}

/**
 * PageSection component
 *
 * @component PageSection
 * @category PageElements
 */
const PageSection = ({ step, components }: Props) => {
  const sectionWidth = step.raw.step_option?.split('=')?.pop()
  const ref = useRef<any>(null)
  const [padHeight, setPadHeight] = useState<number>(0)
  useEffect(() => {
    const element = ref.current
    if (element) {
      const height = element.offsetHeight
      setPadHeight(height)
    }
  }, [])

  if (step.options.checkOption('modules')) {
    return (
      <div className='modulesSection'>
        {components.map((item, index) => (
          <div key={index} className='moduleWrapper'>
            {item}
          </div>
        ))}
      </div>
    )
  }

  if (step.options.checkOption('Notepad')) {
    return (
      <div className={`${step.raw.step_option} singleSection padHeight${padHeight}`} data-testid='notepad-section'>
        <Row>
          <Col lg={getWidthColumns(sectionWidth)}>
            <div className='card-container h-100 mb-4' ref={ref}>
              {components}
            </div>
          </Col>
          <Col lg={getNotepadColumns(sectionWidth)}>
            <div className='notepad card-container'>
              <Notepad
                id='1234'
                title='Task Notepad'
                projectId={step.logic_ref?.getValue('project_id')}
                objectType='task'
                relatedObject={step.logic_ref?.getValue('task_id')}
                createdBy=''
                updatedBy=''
                padHeight={padHeight}
              />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
  return (
    <div className={`section singleSection ${step.raw.step_option} card-container`} data-testid='page-section'>
      {components}
    </div>
  )
}

export default PageSection
