import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { PSLHelper } from '@digitalworkflow/dwtranslateclient'

const browserHistory = createBrowserHistory({})
const reactPluginAppInsights = new ReactPlugin()

// *** Add the Click Analytics plug-in. ***
/* var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
}; */

const myInsightsKey = PSLHelper.AzureInsightsKey()

const appInsights = new ApplicationInsights({
  config: {
    connectionString: myInsightsKey,
    // *** If you're adding the Click Analytics plug-in, delete the next line. ***
    extensions: [reactPluginAppInsights],
    // *** Add the Click Analytics plug-in. ***
    // extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPluginAppInsights.identifier]: { history: browserHistory }
      // *** Add the Click Analytics plug-in. ***
      // [clickPluginInstance.identifier]: clickPluginConfig
    }
  }
})

if (myInsightsKey !== '') appInsights.loadAppInsights()
export { reactPluginAppInsights, appInsights }
