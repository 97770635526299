interface StepProps {
  raw: {
    step_option: string
  }
}
export const getSectionWrapperClassName = (step: StepProps): string => {
  if (!step?.raw || !step.raw.step_option) {
    return 'col-12'
  }

  const { step_option } = step.raw
  const lowercaseOption = step_option.toLowerCase()

  if (lowercaseOption.includes('notepad')) {
    return 'col-12'
  }

  const widthMatch = step_option.match(/size=(\d+)/)
  if (!widthMatch) {
    return 'col-12'
  }

  const widthValue = parseInt(widthMatch[1], 10)

  switch (widthValue) {
    case 75:
      return 'col-md-9'
    case 50:
      return 'col-md-6'
    case 25:
      return 'col-md-3'
    default:
      return 'col-12'
  }
}

export const getWidthColumns = (sectionWidth: string | undefined) => {
  return sectionWidth === '100'
    ? 12
    : sectionWidth === '75'
    ? 9
    : sectionWidth === '50'
    ? 6
    : sectionWidth === '25'
    ? 3
    : 8
}

export const getNotepadColumns = (sectionWidth: string | undefined) => {
  return sectionWidth === '100'
    ? 4
    : sectionWidth === '75'
    ? 3
    : sectionWidth === '50'
    ? 4
    : sectionWidth === '25'
    ? 4
    : 4
}
