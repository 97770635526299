import React from 'react'
import { SBFloorPlan } from '@digitalworkflow/sbpcommon'
import { FormFieldInputProps } from '../useForm'

const SBFloorPlanInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  console.log({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })
  return <SBFloorPlan buildingId='BLHOHO016_417514thAve' editMode />
}
export default SBFloorPlanInput
