import React, { useEffect } from 'react'
import { deleteDatabase } from '../../utils/syncDatabase'
import { getLoginPortalID } from '../../constants'
import { AuthService } from '@digitalworkflow/dwloginclient'
import { PSLHelper } from '@digitalworkflow/dwtranslateclient'

const authService = AuthService.instance()

const Reload = () => {
  useEffect(() => {
    const clearAll = async () => {
      await deleteDatabase()
      authService.authLogout()
      localStorage.clear()
      window.location.href =
        `${PSLHelper.LoginPortalUrl()}/logout/${getLoginPortalID()}?return=` + window.location.hostname
    }

    clearAll()
  }, [])

  return <></>
}

export default Reload
